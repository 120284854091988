import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import './App.css';
import Work from './components/Work';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Home from './components/Home';

function App() {
  return (
    <main id="background">
      <div id="main-container" className="container-fluid">
      <Header />
        <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/work" element={<Work />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          <Footer />
        </Router>
      </div>
    </main>
  );
}

export default App;
