import React, { useRef, useState} from "react";
import emailjs, { init } from "@emailjs/browser";

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    init("pR7o8WGHrrTBEdmbD");
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_p9vf37l", "template_jb0tmvp", form.current, "pR7o8WGHrrTBEdmbD").then(
        (result) => {
            alert("Message Sent Successfully");
            console.log(result.text);
        },
        (error) => {
            console.log(error.text);
        }
        );
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
    };
    return (
        <main id="contactForm">
            <div class="fcf-body">
                <div id="fcf-form">
                    <h1 class="fcf-h1">Contact</h1>
                    <form id="fcf-form-id" class="fcf-form-class" onSubmit={handleSubmit} ref={form}>
                    <div class="fcf-form-group">
                        <label for="Name" class="fcf-label">Your Name</label>
                        <div class="fcf-input-group">
                            <input type="text" 
                                id="Name" 
                                name="Name" 
                                class="fcf-form-control"
                                maxlength="50"
                                onChange={event => setName(event.target.value)}
                                value={name}
                                required>
                            </input>
                        </div>
                    </div>
                    <div class="fcf-form-group">
                        <label for="Email" class="fcf-label">Your email address</label>
                            <div class="fcf-input-group">
                            <input type="email" 
                                id="Email" 
                                name="Email"
                                maxlength="50"
                                class="fcf-form-control"
                                onChange={event => setEmail(event.target.value)}
                                value={email}
                                required>
                            </input>
                        </div>
                    </div>
                    <div class="fcf-form-group">
                        <label for="phone" class="fcf-label">Your Phone Number</label>
                            <div class="fcf-input-group">
                            <input type="tel" 
                                id="phone" 
                                name="phone"
                                maxlength="15"
                                class="fcf-form-control"
                                onChange={event => setPhone(event.target.value)}
                                value={phone}
                                required>
                            </input>
                        </div>
                    </div>
                    <div class="fcf-form-group">
                        <label for="Message" class="fcf-label">Your message</label>
                        <div class="fcf-input-group">
                            <textarea id="Message" 
                                name="Message" 
                                class="fcf-form-control"
                                onChange={event => setMessage(event.target.value)}
                                value={message}
                                rows="6" 
                                maxlength="250"
                                required>
                            </textarea>
                        </div>
                    </div>

                    <div class="fcf-form-group">
                        <button type="submit" id="fcf-button" class="fcf-btn fcf-btn-primary fcf-btn-lg fcf-btn-block">Send Message</button>
                    </div>
                </form>
                </div>
            </div>
        </main>
        );
    }

export default Contact;