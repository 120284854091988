import React from 'react';

const Work = () => {
    return (
        <main id="gallery">
            <div className="row">
                        <h1 id="titleSecondary">Gallery</h1>
                        <div className="col-12">
                            <h3><u>Dump Truck</u></h3>
                            <ul>
                                <li>Holes cut for LED lights</li>
                                <li>Old lift gate frame cut off</li>
                                <li>Reinforced existing frame</li>
                                <li>Straightened out truck body sections that were bent up</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\DumpTruck_1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\DumpTruck_4.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\DumpTruck_2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\DumpTruck_3.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Bucket Repair</u></h3>
                                <ul>
                                    <li>Re-enfored and rebuilt bucket</li>
                                    <li>Welded quick attach plate</li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\BucketRepair1.jpg" alt="Work Gallery"></img>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\BucketRepair2.jpg" alt="Work Gallery"></img>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\BucketRepair3.jpg" alt="Work Gallery"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Heavy Equipment Repair</u></h3>
                            <ul>
                                <li>Cutout wear plates</li>
                                <li>Replaced wear plates</li>
                                <li>Fabricated and welded new clean out fingers for feed drum</li>
                                <li>Welded new teeth to feed wheel</li>
                                <li>Rebuilt hog box</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\Equip1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\Equip2.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Heavy Equipment Repair</u></h3>
                                <ul>
                                    <li>Welded and installed new thumb grapple to stick on excavator</li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\Equipment1.jpg" alt="Work Gallery"></img>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\Equipment2.jpg" alt="Work Gallery"></img>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\Equipment3.jpg" alt="Work Gallery"></img>
                                </div>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Historic Piece Repair</u></h3>
                            <ul>
                                <li>Reset Cannon Balls and welding to pillar</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HistoricRepair1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HistoricRepair2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HistoricRepair3.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HistoricRepair4.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Indoor Warehouse Repair</u></h3>
                            <ul>
                                <li>Repaired fork lift guardrails</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\New\WareHouse1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\New\WareHouse2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\New\WareHouse3.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Trailer Repair</u></h3>
                            <ul>
                                <li>Installed new fenders &#40;Not insured to weld on personal vehicle/trailer hitches or frames &#41;</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\New\TrailerRepair1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\New\TrailerRepair2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\New\TrailerRepair3.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Heavy Equipment Repair</u></h3>
                            <ul>
                                <li>Cutout wear plates</li>
                                <li>Replaced wear plates</li>
                                <li>Fabricated and welded new clean out fingers for feed drum</li>
                                <li>Welded new teeth to feed wheel</li>
                                <li>Rebuilt hog box</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\Heavy1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\Heavy2.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Snow Plow Blade Repair</u></h3>
                            <ul>
                                <li>Fixed bends and cracks</li>
                                <li>Patching rust spots</li>
                                <li>replaced cutting edge</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\SnowPlow1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Skid Loader Repair</u></h3>
                            <ul>
                                <li>Installed water tank and pump on sweeper</li>
                                <li>Fabricated new brackets</li>
                                <li>Built watering pipe guard</li>
                            </ul>
                        </div>
                        <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\SkidLoad1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Heavy Equipment Repair</u></h3>
                            <ul>
                                <li>Root Rake: Built up shanks</li>
                                <li>Installed new wear plates on bottom</li>
                                <li>Installed new tips</li>
                                <li>Hardfaced tips and tusk</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Heavyequip1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Heavyequip2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Heavyequip3.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Heavyequip4.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Frames built for Straw Blower Unit</u></h3>
                            <ul>
                            <li>Skid loader can now come in at any side and pick up with forks</li>
                                <li>Width is the same all around</li>
                                <li>No need to get out and adjust fork width every time</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\Frames2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\Frames1.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Trailer Repair</u></h3>
                            <ul>
                                <li>Welded new tie down anchor pockets</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\Trailer1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\Trailer2.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Bucket Repair</u></h3>
                            <ul>
                                <li>Cut out and replaced floor</li>
                                <li>Welded new wear plates</li>
                                <li>Hardfacing</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\BuckRepair1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\BuckRepair2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\BuckRepair3.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\BuckRepair4.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Farm Equipment Repair</u></h3>
                                <ul>
                                    <li>Crossarm repaired</li>
                                    <li>Weld and install gussets</li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\FarmEquipment1.jpg" alt="Work Gallery"></img>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\FarmEquipment2.jpg" alt="Work Gallery"></img>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <img className="img-fluid" src="\IMG\New\FarmEquipment3.jpg" alt="Work Gallery"></img>
                                </div>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Heavy Equipment Repair</u></h3>
                            <ul>
                                <li>Replace grapple with stump shear/</li>
                                <li>Welded stick plate for thumb boss-pin</li>
                                <li>Replaced knives and hardfacing</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HeavRepair1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HeavRepair2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HeavRepair3.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\HeavRepair4.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Truck Bed</u></h3>
                            <ul>
                                <li>Fabricated truck bed welder rack</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\New\TruckBed1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Bucket Repair</u></h3>
                            <ul>
                                <li>Repaired cracks</li>
                                <li>Added wear plates</li>
                                <li>Welded on new shanks</li>
                                <li>Installed new teeth</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Bucket1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Bucket2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Bucket3.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\New\Bucket4.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                            <h3><u>Handrails</u></h3>
                            <ul>
                                <li>Made sturdy for support and stability</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails4.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails3.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>HorseTrailer</u></h3>
                            <ul>
                                <li>Fixed up and ready to go</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\HorseTrailers2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\HorseTrailers1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\HorseTrailers.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\HorseTrailers3.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Pipe Casing</u></h3>
                            <ul>
                                <li>36" Pipe Casing ready for use</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\PipeCasing.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\PipeCasing5.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\PipeCasing3.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\PipeCasing4.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Skid Loader</u></h3>
                            <ul>
                                <li>Extra support added</li>
                                <li>Tubing installed</li>
                                <li>Ready to move the bleachers</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoader.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoader4.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoader2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoader3.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Skid Loader Bucket</u></h3>
                            <ul>
                                <li>Bottom of Skid Loader Bucket cut out and replaced</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoaderBucket.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoaderBucket1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoaderBucket2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\SkidLoaderBucket3.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Snow Plow</u></h3>
                            <ul>
                                <li>Set for truck attachment</li>
                                <li>Ready for the roads</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\SnowPlows.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\SnowPlows1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\SnowPlows2.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Trailer Fender</u></h3>
                            <ul>
                                <li>Trailer fenders replaced</li>
                                <li>Cracks and bent metal repaired</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\TrailerFenders.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\TrailerFenders1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\TrailerFenders4.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\TrailerFenders2.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Dump Truck</u></h3>
                            <ul>
                                <li>Doors Fabricated</li>
                                <li>Latches Installed</li>
                                <li>Hinges Installed</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\DumpTruck.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img className="img-fluid" src="\IMG\DumpTruck1.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>Handrails</u></h3>
                            <ul>
                                <li>Ready for use</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\Handrails_New.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\Handrails_New1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <img className="img-fluid" src="\IMG\Handrails_New2.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="workSect">
                        <h3><u>More Handrails</u></h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails_Again.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails_Again1.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails_Again2.jpg" alt="Work Gallery"></img>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                                <img className="img-fluid" src="\IMG\Handrails_Again5.jpg" alt="Work Gallery"></img>
                            </div>
                        </div>
                    </div>
                </main>
            );
}

export default Work;