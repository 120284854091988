import React from 'react';

const Home = () => {
    return (
        <section>
            <div className="row description">
                <h4><u>SMAW-Unlimited Certified and Insured</u></h4>
                <p className="description">Portable welding, fabrication, and cutting services. Serving <u>Gettysburg</u>, <u>Pennsylvania</u> and surrounding areas.</p>
            </div>
                <div className="row">
                    <img id="workTruckIMG" className="img-fluid" src="IMG\WorkTruck.jpg" alt="Something Cool"></img>
                </div><br></br>
                <h2 className="title">Services</h2>
                <p className="description">
                    Custom Fabrication |
                    Arc Gouging |
                    Carbon Steel | <br></br><br></br>
                    Hardfacing/Build Up |
                    Equipment Repair |
                    Railing Repair |<br></br><br></br>
                    General Repairs & Modifications |
                    Mobile Welding</p>
        </section>
    );
}

export default Home;