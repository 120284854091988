import React from 'react';

const Header = () => {
    return (
        <div>
            <h1 className="title">John's Welding Services</h1>
            <div className="row">
                    <ul class="links">
                        <div className="button"><a className='link' href="/">Home</a></div>
                        <div className="button"><a className='link' href="/work">Gallery</a></div>
                        <div className="button"><a className='link' href="/contact">Contact</a></div>
                    </ul>
                </div>
        </div>
    );
}

export default Header;