import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p><strong>John's Welding Services </strong><br></br>
            <img className="footIMG" src="\IMG\phonecall.png"  alt="icon"></img>  410-925-3612<br></br>
            <img className="footIMG" src="\IMG\email.png"  alt="icon"></img>  <a className= "footEmail" href="mailto:jbaier242@gmail.com">jbaier242@gmail.com</a><br></br>
            <a href="https://www.facebook.com/Johns-Welding-Services-686290171475989" rel="noreferrer" target="_blank"><img className="footIMG" src="\IMG\facebook.png" alt="icon"></img></a></p>
        </footer>
    );
}

export default Footer;